.cert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 50px;
}

.cert-card {
  max-width: 300px; /* Maintain a reasonable width on larger screens */
  flex: 1 1 calc(33% - 20px); /* Three cards per row on larger screens */
  margin: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.cert-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.cert-card:hover {
  transform: scale(1.05); /* Hover effect */
}

.cert-heading {
  text-align: center;
  font-size: 2.2em;
  color: #c084fc; /* Purple heading */
  margin-bottom: 40px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .cert-card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
    max-width: 100%; /* Allow cards to use full width */
  }

  .cert-heading {
    font-size: 1.8em; /* Adjust heading size for mobile */
  }
}

@media (max-width: 480px) {
  .cert-card {
    flex: 1 1 100%; /* One card per row on small screens */
    max-width: 100%; /* Allow cards to use full width */
  }

  .cert-heading {
    font-size: 1.6em; /* Adjust heading size for small screens */
    margin-bottom: 30px; /* Reduced margin for small screens */
  }
}
